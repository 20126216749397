


























import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import ChevronUpIcon from '@/assets/img/icons/chevron-up.svg?sprite'
import ChevronDownIcon from '@/assets/img/icons/chevron-down.svg?sprite'

gsap.registerPlugin(Draggable)

@Component({
    components: {
        ChevronUpIcon,
        ChevronDownIcon
    }
})
export default class InputRange extends Vue {
    @Prop({ default: 0 }) min!: number
    @Prop({ default: 0 }) max!: number
    @Prop({ default: 0 }) value!: number
    @Prop({ default: 1 }) step!: number
    @Prop() color!: string

    currentValue = this.value
    thumbIsDragging = false

    thumb!: Draggable

    get progressStyle(): object {
        return {
            transform: `scaleY(${this.currentValue / this.max})`
        }
    }

    mounted() {
        this.thumb = Draggable.create(
            this.$refs.thumb as HTMLElement,
            {
                type: 'y',
                bounds: this.$refs.thumbWrapper,
                zIndexBoost: false,
                onPress: event => event.stopPropagation(),
                onDrag: this.onDrag,
                onDragEnd: this.onDragEnd
            } as GSAPDraggableVars
        )[0]

        this.updateThumbPosition()
    }

    updateThumbPosition() {
        gsap.set(this.thumb.target, {
            y: (this.currentValue / this.max - 1) * -1 * this.thumb.maxY,
            onUpdate: this.thumb.update
        })
    }

    onDrag() {
        this.thumbIsDragging = true
        this.currentValue = (this.thumb.y / this.thumb.maxY - 1) * -1 * this.max
    }

    onDragEnd() {
        this.thumbIsDragging = false
    }

    onThumbTrackClick(event: MouseEvent) {
        this.currentValue = (event.offsetY / this.thumb.maxY - 1) * -1 * this.max
    }

    onThumbKeyDown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowLeft':
                this.currentValue -= this.step
                break

            case 'ArrowUp':
            case 'ArrowRight':
                this.currentValue += this.step
                break
        }
    }

    @Watch('currentValue')
    onCurrentValueChange() {
        this.$emit('input', this.currentValue)
    }

    @Watch('value')
    onValueChange() {
        if (!this.thumb.isDragging) this.updateThumbPosition()
    }
}
