
































































































































































































import { Component, Ref, Watch } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import '@google/model-viewer'
import Color from '@/constants/color'
import ButtonIcon from '@/components/ButtonIcon.vue'
import SunIcon from '@/assets/img/icons/sun.svg?sprite'
import MoonIcon from '@/assets/img/icons/moon.svg?sprite'
import IconColor from '@/assets/img/icons/color.svg?sprite'
import IconPoints from '@/assets/img/icons/points.svg?sprite'
import IconChangeCamera from '@/assets/img/icons/change-camera.svg?sprite'
import IconVariants from '@/assets/img/icons/variants.svg?sprite'
import InputRange from '@/components/InputRange.vue'
import { ModelViewer, ModelViewerCamera, ModelViewerSceneExtras } from '@/@types/model-viewer'
import Markdown from '@/components/Markdown.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { Getter, namespace, State } from 'vuex-class'
import gsap from 'gsap'
import ScrollerElement from '@/components/ScrollerElement.vue'
import ErrorScreen from '@/components/ErrorScreen.vue'
import HttpError from '@/errors/HttpError'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'
import { RemoteSlide } from '@/@types/store'
import { getBreakpointValue, mediaIsMin } from '@/utils/media'
import { Material, RGBA, Texture } from '@google/model-viewer/lib/features/scene-graph/api'
import { resolveSlug } from '@/utils/alias-resolver'
import BasePicker, { PickerOptionGroup } from '@/components/BasePicker.vue'
import { ListOption } from '@/components/BaseList.vue'
import Shepherd from 'shepherd.js'
import Vue from 'vue'
import MutationType from '@/constants/mutation-type'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'

const MIN_FIELD_OF_VIEW = 80
const MAX_FIELD_OF_VIEW = 50
const MIN_SCREEN_WIDTH = 375
const MAX_SCREEN_WIDTH = 1440

const LIGHT_ENVIRONMENT = 'lightEnvironment'
const DARK_ENVIRONMENT = 'darkEnvironment'

// names of the materials exported from the studio app (3d soft)
const MATERIAL_RECTO_LEGACY_NAME = 'Boegli_2Sides_MAT1'
const MATERIAL_NAME_SUFFIX = 'Boegli_'
// leather
const LEATHER_STITCH_BASE_FILENAME = 'Auto_Leather_MAT_base'
const LEATHER_BASE_FILENAME = 'Auto_Leather_unstitched_MAT_base'
const LEATHER_STITCH_MATERIAL_NAME = 'Auto_leather_stitch'
const LEATHER_STITCH_MATERIAL_LEGACY_NAME = 'Auto_Leather_MAT'
const LEATHER_MATERIAL_NAME = 'Auto_leather'
const LEATHER_MATERIAL_LEGACY_NAME = 'Auto_Leather_unstitched_MAT'
const SECONDARY_LEATHER_MATERIAL_NAME = 'Auto_leather_2'
const SECONDARY_LEATHER_MATERIAL_LEGACY_NAME = 'Auto_Leather_unstitched_2_MAT'

interface LeatherVariant {
    id: string
    label: string
    stitchTextureSrc: string
    textureSrc: string
    thumbSrc: string
}

type SecondaryLeatherVariant = Omit<LeatherVariant, 'stitchTextureSrc'>

const LEATHER_BASE_URL = 'https://discover-assets.boegli.ch/leather_presets/'
const LEATHER_NAMES: string[] = [
    'White-Black',
    'White-Red',
    'White-Blue',
    'Grey-White',
    'Grey-Red',
    'Grey-Blue',
    'Tobacco-White',
    'Tobacco-Black',
    'Brown-White',
    'Brown-Yellow',
    'Black-White',
    'Black-Red',
    'Black-Blue',
    'Blue-White',
    'Blue-Yellow',
    'Red-White',
    'Red-Black',
    'Green-Yellow'
]
const LEATHER_VARIANTS: LeatherVariant[] = LEATHER_NAMES.map((name, index) => {
    const fileNameIndex = ('0' + (index + 1).toString()).slice(-2)
    const stitchTextureSrc = `${LEATHER_BASE_URL}${fileNameIndex}_${LEATHER_STITCH_BASE_FILENAME}_${name}.png`

    return {
        id: name.toLowerCase(),
        label: name.replace('-', '/'),
        stitchTextureSrc,
        thumbSrc: stitchTextureSrc.replace('.png', '_64x64.png'),
        textureSrc: `${LEATHER_BASE_URL}${LEATHER_BASE_FILENAME}_${name.split('-')[0]}.png`
    }
})

const SECONDARY_LEATHER_NAMES: string[] = ['White', 'Grey', 'Tobacco', 'Brown', 'Black', 'Blue', 'Red', 'Green']
const SECONDARY_LEATHER_VARIANTS: SecondaryLeatherVariant[] = SECONDARY_LEATHER_NAMES.map(name => {
    const textureSrc = `${LEATHER_BASE_URL}${LEATHER_BASE_FILENAME}_${name}.png`

    return {
        id: name.toLowerCase(),
        label: name,
        textureSrc,
        thumbSrc: textureSrc.replace('.png', '_64x64.png')
    }
})

const LEATHER_VARIANT_ID = 'first'
const SECONDARY_LEATHER_VARIANT_ID = 'second'

const remoteModule = namespace(ModuleNamespace.REMOTE)

const TOUR_STATE_STORAGE_ITEM_ID = 'sample-viewer-tour-state'
const TOUR_STEPS: string[] = ['color', 'environment', 'sample', 'camera', 'texture']
const TOUR_STEP_ID_PREFIX = 'step-'

enum TourState {
    COMPLETE = 'complete'
}

function calculateTourStepOverlayRadius(element: HTMLElement): number {
    return element.clientWidth / 2
}

@Component({
    components: {
        BasePicker,
        ButtonIcon,
        SunIcon,
        MoonIcon,
        IconColor,
        IconPoints,
        IconChangeCamera,
        IconVariants,
        InputRange,
        Markdown,
        ProgressBar,
        ScrollerElement,
        ErrorScreen
    }
})
export default class SlideSampleViewer extends mixins(Slide) {
    @State uiColor!: Color
    @State isLeader!: boolean
    @State hasPresentingLeader!: boolean
    @remoteModule.State('slides') remoteSlides!: Array<RemoteSlide>
    @remoteModule.State('sampleViewerControlsAllowed') controlsAllowed!: boolean

    @Getter navigationIsAllowed!: boolean
    @Getter displaySampleViewerTour!: boolean

    @Ref() viewer!: ModelViewer
    @Ref() progressBar!: ProgressBar | undefined

    $refs!: {
        loading: HTMLElement
        controls: HTMLElement
        subtitle: HTMLElement
        title: HTMLElement
        description: Markdown
        exposureInput: Vue
        environmentButton?: Vue
        sampleButton?: Vue
        colorButton?: Vue
        cameraButton?: Vue
        textureButton?: Vue
    }

    viewerIsLoading = false
    viewerLoaded = false
    exposure = 50
    realSlug = resolveSlug(this.walker)
    dragEnabled = false
    viewerError: Error | null = null
    autoRotate = true
    cameraOrbit = '-1rad 1.5rad'
    minCameraOrbit = 'auto'
    maxCameraOrbit = 'auto'
    cameraTarget = 'auto auto auto'
    fieldOfView = 0
    materialColor: Record<string, string> = {}
    activeSampleId = (this.walker.item as SlideWalkerItemSampleViewer).sample?.[0]?.['@id'] || ''
    nextSampleId = this.activeSampleId
    sampleUpdated = false
    previousSampleImage: string | null = null
    loadingIsTransparent = true
    cameras: ModelViewerCamera[] = []
    cameraIndex = 0
    tapDistance = 2
    panning = false
    panX: number[] = []
    panY: number[] = []
    startX = 0
    startY = 0
    lastX = 0
    lastY = 0
    metersPerPixel = 0
    leatherMaterials: Material[] = []
    leatherStitchMaterials: Material[] = []
    secondaryLeatherMaterials: Material[] = []
    leatherVariant: Record<string, string> = {}
    leatherDefaultTexture: Texture | null = null
    leatherDefaultStitchTexture: Texture | null = null
    secondaryLeatherDefaultTexture: Texture | null = null
    tour: Shepherd.Tour | null = null

    get walkerItem() {
        return this.walker.item as SlideWalkerItemSampleViewer
    }

    get sample(): Sample | undefined {
        const index = this.walkerItem.sample?.findIndex(sample => this.activeSampleId === sample['@id'])

        return this.walkerItem.sample?.[index && index !== -1 ? index : 0]
    }

    get overTitle(): string {
        return this.sample?.overTitle || ''
    }

    get title(): string {
        return this.sample?.title || ''
    }

    get description(): string {
        return this.sample?.description || ''
    }

    get viewerSrc(): string | boolean {
        return this.viewerIsLoading || this.viewerLoaded
            ? process.env.VUE_APP_API_URL + (this.sample?.embossing.glb || '')
            : false
    }

    get environmentImage(): string | null {
        const key = this.color === Color.DARK ? DARK_ENVIRONMENT : LIGHT_ENVIRONMENT
        const environment = this.sample?.environments.find(environment => key in environment)
        const url: string | undefined = environment?.[key]

        if (!url) return null

        return process.env.VUE_APP_API_URL + url
    }

    get responsiveFieldOfView() {
        let result

        if (this.fieldOfView) {
            const windowAspect = this.windowWidth / window.innerHeight
            const cameraAspect = 16 / 9 // assume the camera is setup for this aspect ratio
            // const fovRatio = 0.6 // magic number for correcting the fov from the Studio

            // result = this.fieldOfView * fovRatio
            result = this.fieldOfView

            if (windowAspect < cameraAspect) {
                const ratio = windowAspect / cameraAspect

                result *= 1 + (1 - ratio) // increase the fov

                // portrait orientation
                if (windowAspect < 1) {
                    result *= 1 + (1 - windowAspect) // increase again the fov
                }
            }
        } else {
            let widthRatio = (this.windowWidth - MIN_SCREEN_WIDTH) / (MAX_SCREEN_WIDTH - MIN_SCREEN_WIDTH)
            if (widthRatio < 0) widthRatio = 0
            else if (widthRatio > 1) widthRatio = 1

            result = MIN_FIELD_OF_VIEW + (MAX_FIELD_OF_VIEW - MIN_FIELD_OF_VIEW) * widthRatio
        }

        return result + 'deg'
    }

    get hasColorToggle() {
        return (
            typeof this.sample?.environments.find(environment => LIGHT_ENVIRONMENT in environment) !== 'undefined' &&
            typeof this.sample?.environments.find(environment => DARK_ENVIRONMENT in environment) !== 'undefined'
        )
    }

    get sampleColors() {
        const colorSwatch = this.walkerItem.colorSwatch.flatMap(colorSwatch => colorSwatch.colors) || []

        return (this.walkerItem.colors || []).concat(colorSwatch)
    }

    get colorPickerOptions(): PickerOptionGroup[] {
        return ['recto', 'verso'].map(value => ({
            value,
            label: this.$t(`material_color_${value}_tab`).toString(),
            options: this.sampleColors.map((color: ColorData) => ({
                label: color.title,
                value: color.color,
                color: color.color
            }))
        }))
    }

    get leatherPickerOptions(): PickerOptionGroup[] | ListOption[] {
        const groups = [
            {
                value: LEATHER_VARIANT_ID,
                label: this.$t('leather_top').toString(),
                options: LEATHER_VARIANTS.map(variant => ({
                    label: variant.label,
                    value: variant.id,
                    image: {
                        src: variant.thumbSrc,
                        alt: variant.label,
                        width: 64,
                        height: 64
                    }
                }))
            }
        ]

        if (this.secondaryLeatherMaterials.length) {
            groups.push({
                value: SECONDARY_LEATHER_VARIANT_ID,
                label: this.$t('leather_bottom').toString(),
                options: SECONDARY_LEATHER_VARIANTS.map(variant => ({
                    label: variant.label,
                    value: variant.id,
                    image: {
                        src: variant.thumbSrc,
                        alt: variant.label,
                        width: 64,
                        height: 64
                    }
                }))
            })
        }

        return groups
    }

    get materialColorIsEmpty() {
        return !Object.keys(this.materialColor).length
    }

    get viewerControlsAllowed() {
        return this.controlsAllowed || this.navigationIsAllowed
    }

    get samplePickerOptions(): ListOption[] {
        return (
            this.walkerItem.sample?.map(sample => ({
                label: sample.title,
                image: sample.thumbnail[0],
                value: sample['@id'],
                selected: sample['@id'] === this.activeSampleId
            })) || []
        )
    }

    get cameraPickerOptions(): ListOption[] {
        return (
            this.cameras?.map((camera, index) => ({
                label: camera.name || this.$t('camera_view_{index}', { index: index + 1 }).toString(),
                value: index,
                selected: index === this.cameraIndex
            })) || []
        )
    }

    get popoverAttrs(): Record<string, unknown> {
        return {
            offset: 15,
            popoverClass: this.$style.controlPopover,
            placement: this.windowWidth >= getBreakpointValue('md') ? 'left' : 'top',
            boundariesElement: 'viewport'
        }
    }

    get hasActiveTour(): boolean {
        return this.$store.state.activeTourSlideIndexes.includes(this.index)
    }

    get tourIsAllowed(): boolean {
        return (
            !this.isLeader &&
            this.isCurrent &&
            this.displaySampleViewerTour &&
            (!this.hasPresentingLeader || this.controlsAllowed)
        )
    }

    mounted() {
        eventBus.$on(EventType.START_TOUR, this.onStartTour)
    }

    beforeDestroy() {
        eventBus.$off(EventType.START_TOUR, this.onStartTour)

        this.disposeTour()
    }

    populateAssetPromises() {
        if (!this.sample) return

        const modelViewerPromise = new Promise<void>(resolve => {
            if (this.viewerLoaded) {
                resolve()
                return
            }

            const unwatch = this.$watch('viewerLoaded', function() {
                resolve()
                unwatch()
            })
        })

        this.assetPromises.push(modelViewerPromise)
    }

    toggleColor() {
        this.color = this.color === Color.DARK ? Color.LIGHT : Color.DARK
    }

    // method to reset the turntable rotation seamlessly (without apparent movement - except for the environment)
    resetTurntable() {
        const tableRotation = this.viewer.turntableRotation
        const cameraOrbit = this.viewer.getCameraOrbit()

        this.viewer.resetTurntableRotation(0)
        this.viewer.cameraOrbit = `${cameraOrbit.theta - tableRotation}rad ${cameraOrbit.phi}rad ${cameraOrbit.radius}m`
        this.viewer.jumpCameraToGoal()
    }

    checkIfViewerShouldLoad() {
        if (this.isEnoughVisible && !this.viewerLoaded && !this.transitionIsVisible && !this.transitionIsAnimated) {
            this.viewerIsLoading = true
        }
    }

    checkIfTourShouldStart() {
        this.$nextTick(() => {
            if (
                this.tourIsAllowed &&
                this.isEnoughVisible &&
                this.viewerLoaded &&
                !this.transitionIsVisible &&
                !this.transitionIsAnimated &&
                localStorage.getItem(TOUR_STATE_STORAGE_ITEM_ID) !== TourState.COMPLETE
            ) {
                this.startTour()
            }
        })
    }

    afterIsEnoughVisibleChange() {
        this.checkIfViewerShouldLoad()
        this.checkIfTourShouldStart()
    }

    hideLoading() {
        gsap.to(this.$refs.loading, {
            autoAlpha: 0,
            duration: 0.7
        }).then(() => {
            if (this.previousSampleImage) {
                URL.revokeObjectURL(this.previousSampleImage)
                this.previousSampleImage = null
            }
        })
    }

    populateAppear(timeline: GSAPTimeline) {
        const textElements = [this.$refs.subtitle, this.$refs.title]
        if (this.$refs.description) textElements.push(this.$refs.description.$el as HTMLElement)

        timeline
            .from(
                textElements,
                {
                    y: 80,
                    opacity: 0,
                    duration: 0.8,
                    stagger: 0.1
                },
                0.5
            )
            .from(
                this.$refs.controls,
                {
                    opacity: 0,
                    duration: 0.8
                },
                0.8
            )
    }

    initCamera() {
        const camera = this.cameras[this.cameraIndex]

        if (!camera) return

        const minOrbit = camera['min-camera-orbit']?.split(' ') // [theta, phi, radius]
        const maxOrbit = camera['max-camera-orbit']?.split(' ') // [theta, phi, radius]
        // mimics the 3d soft behavior i.e. if the difference between the theta angles are greater or equal to 718 (-359° / 359° rotation) then the yaw is infinite
        const isInfiniteYaw =
            minOrbit && maxOrbit && Math.abs(parseFloat(minOrbit[0])) + Math.abs(parseFloat(maxOrbit[0])) >= 718

        this.autoRotate = false

        if (minOrbit) {
            // inverts the max and min theta / phi angles because of a different axis reference between Unreal engine (3d soft) and ThreeJS
            const theta = isInfiniteYaw ? '-Infinity' : maxOrbit?.[0] || '-Infinity'
            const phi = maxOrbit?.[1] || '22.5deg'
            const radius = minOrbit?.[2] || 'auto'

            this.viewer.minCameraOrbit = `${theta} ${phi} ${radius}`
        }

        if (maxOrbit) {
            // inverts the max and min theta / phi angles because of a different axis reference between Unreal engine (3d soft) and ThreeJS
            const theta = isInfiniteYaw ? 'Infinity' : minOrbit?.[0] || 'Infinity'
            const phi = minOrbit?.[1] || '157.5deg'
            const radius = maxOrbit?.[2] || 'auto'

            this.viewer.maxCameraOrbit = `${theta} ${phi} ${radius}`
        }

        // wait one frame for allow the animation
        this.$nextTick(() => {
            if (camera['camera-orbit']) this.viewer.cameraOrbit = camera['camera-orbit']

            if (camera['camera-target']) {
                this.viewer.cameraTarget = camera['camera-target'].map(value => value + 'm').join(' ')
            }
        })

        if (typeof camera.fov !== 'undefined') this.fieldOfView = camera.fov
    }

    initMaterialColor() {
        const materialColor: Record<string, string> = {}

        this.viewer.model.materials.forEach(material => {
            const materialName = material.name.toLowerCase()
            const nameSuffix = MATERIAL_NAME_SUFFIX.toLowerCase()

            if (
                materialName.includes(nameSuffix + 'recto') ||
                materialName === MATERIAL_RECTO_LEGACY_NAME.toLowerCase()
            ) {
                materialColor.recto = this.materialColor.recto || ''
            } else if (materialName.includes(nameSuffix + 'verso')) {
                materialColor.verso = this.materialColor.verso || ''
            }
        })

        this.materialColor = materialColor
    }

    updateModelMaterialColor() {
        if (!this.viewer?.model) return

        Object.keys(this.materialColor).forEach(key => {
            const color = this.materialColor?.[key]
            const material = this.viewer.model.materials.find(material => {
                const materialName = material.name.toLowerCase()

                // recto available names are: BG_Recto, BG_Recto_Trans, BG_Recto_Alpha
                // verso available names are: BG_Verso, BG_Verso_Trans, BG_Verso_Alpha
                return (
                    materialName.includes(MATERIAL_NAME_SUFFIX.toLowerCase() + key) ||
                    (key === 'recto' && materialName === MATERIAL_RECTO_LEGACY_NAME.toLowerCase()) // legacy material name
                )
            })

            if (material) {
                const rgb = color ? gsap.utils.splitColor(color) : [255, 255, 255]
                const colorFactor: RGBA = [rgb[0] / 255, rgb[1] / 255, rgb[2] / 255, 1]

                material.pbrMetallicRoughness.setBaseColorFactor(colorFactor)
            }
        })
    }

    initLeatherVariant() {
        const variant: Record<string, string> = {}
        const hasLeatherMaterial =
            typeof this.viewer.model.materials.find(
                material =>
                    material.name.toLowerCase() === LEATHER_MATERIAL_NAME.toLowerCase() ||
                    material.name.toLowerCase() === LEATHER_MATERIAL_LEGACY_NAME.toLowerCase() ||
                    material.name.toLowerCase() === LEATHER_STITCH_MATERIAL_NAME.toLowerCase() ||
                    material.name.toLowerCase() === LEATHER_STITCH_MATERIAL_LEGACY_NAME.toLowerCase()
            ) !== 'undefined'
        const hasSecondaryLeatherMaterial =
            typeof this.viewer.model.materials.find(
                material =>
                    material.name.toLowerCase() === SECONDARY_LEATHER_MATERIAL_NAME.toLowerCase() ||
                    material.name.toLowerCase() === SECONDARY_LEATHER_MATERIAL_LEGACY_NAME.toLowerCase()
            ) !== 'undefined'

        if (hasLeatherMaterial) variant[LEATHER_VARIANT_ID] = ''
        if (hasSecondaryLeatherMaterial) variant[SECONDARY_LEATHER_VARIANT_ID] = ''

        this.leatherVariant = variant
    }

    async updateLeatherTexture() {
        if (!this.viewer?.model) return

        const variant = LEATHER_VARIANTS.find(variant => variant.id === this.leatherVariant[LEATHER_VARIANT_ID])
        const texture = this.leatherMaterials.length && variant && (await this.viewer.createTexture(variant.textureSrc))
        const stitchTexture =
            this.leatherStitchMaterials.length && variant && (await this.viewer.createTexture(variant.stitchTextureSrc))

        if (this.leatherMaterials.length) {
            if (!this.leatherDefaultTexture) {
                this.leatherDefaultTexture =
                    this.leatherMaterials[0].pbrMetallicRoughness['baseColorTexture']?.texture || null
            }

            this.leatherMaterials.forEach(material => {
                material.pbrMetallicRoughness['baseColorTexture']?.setTexture(
                    variant && texture ? texture : this.leatherDefaultTexture
                )
            })
        }

        if (this.leatherStitchMaterials.length) {
            if (!this.leatherDefaultStitchTexture) {
                this.leatherDefaultStitchTexture =
                    this.leatherStitchMaterials[0].pbrMetallicRoughness['baseColorTexture']?.texture || null
            }

            this.leatherStitchMaterials.forEach(material => {
                material.pbrMetallicRoughness['baseColorTexture']?.setTexture(
                    variant && stitchTexture ? stitchTexture : this.leatherDefaultStitchTexture
                )
            })
        }

        if (this.secondaryLeatherMaterials.length) {
            const variant = SECONDARY_LEATHER_VARIANTS.find(
                variant => variant.id === this.leatherVariant[SECONDARY_LEATHER_VARIANT_ID]
            )
            const texture = variant && (await this.viewer.createTexture(variant.textureSrc))

            if (!this.secondaryLeatherDefaultTexture) {
                this.secondaryLeatherDefaultTexture =
                    this.secondaryLeatherMaterials[0].pbrMetallicRoughness['baseColorTexture']?.texture || null
            }

            this.secondaryLeatherMaterials.forEach(material => {
                material.pbrMetallicRoughness['baseColorTexture']?.setTexture(
                    variant && texture ? texture : this.secondaryLeatherDefaultTexture
                )
            })
        }
    }

    updateScene() {
        const slide = this.remoteSlides.find(slide => slide.id === this.realSlug)

        if (!slide) return

        const {
            sampleExposure,
            sampleColor,
            sampleAutoRotate,
            sampleCameraOrbit,
            sampleMinCameraOrbit,
            sampleMaxCameraOrbit,
            sampleCameraTarget,
            sampleFieldOfView,
            sampleMaterialColor,
            sampleNextSampleId,
            sampleLeatherVariant
        } = slide

        if (sampleNextSampleId && sampleNextSampleId.length && !this.viewerControlsAllowed) {
            this.nextSampleId = sampleNextSampleId
        }

        if (this.nextSampleId !== this.activeSampleId) return

        if (typeof sampleAutoRotate !== 'undefined') this.autoRotate = sampleAutoRotate

        if (!this.viewerControlsAllowed) {
            if (typeof sampleExposure !== 'undefined') this.exposure = sampleExposure

            if (sampleColor) this.color = sampleColor

            if (sampleCameraOrbit) {
                if (this.viewer) this.viewer.cameraOrbit = sampleCameraOrbit
                else this.cameraOrbit = sampleCameraOrbit
            }

            if (sampleMinCameraOrbit) {
                if (this.viewer) this.viewer.minCameraOrbit = sampleMinCameraOrbit
                else this.minCameraOrbit = sampleMinCameraOrbit
            }

            if (sampleMaxCameraOrbit) {
                if (this.viewer) this.viewer.maxCameraOrbit = sampleMaxCameraOrbit
                else this.maxCameraOrbit = sampleMaxCameraOrbit
            }

            if (sampleCameraTarget) {
                if (this.viewer) this.viewer.cameraTarget = sampleCameraTarget
                else this.cameraTarget = sampleCameraTarget
            }

            if (sampleFieldOfView) this.fieldOfView = sampleFieldOfView

            if (sampleMaterialColor) this.materialColor = sampleMaterialColor

            if (typeof sampleLeatherVariant !== 'undefined') this.leatherVariant = sampleLeatherVariant
        }
    }

    startPan() {
        const orbit = this.viewer.getCameraOrbit()
        const { theta, phi, radius } = orbit
        const psi = theta - this.viewer.turntableRotation

        this.metersPerPixel = (0.75 * radius) / this.viewer.getBoundingClientRect().height
        this.panX = [-Math.cos(psi), 0, Math.sin(psi)]
        this.panY = [-Math.cos(phi) * Math.sin(psi), Math.sin(phi), -Math.cos(phi) * Math.cos(psi)]

        this.viewer.interactionPrompt = 'none'
    }

    movePan(x: number, y: number) {
        const dx = (x - this.lastX) * this.metersPerPixel
        const dy = (y - this.lastY) * this.metersPerPixel

        const target = this.viewer.getCameraTarget()
        target.x += dx * this.panX[0] + dy * this.panY[0]
        target.y += dx * this.panX[1] + dy * this.panY[1]
        target.z += dx * this.panX[2] + dy * this.panY[2]

        this.viewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`

        this.lastX = x
        this.lastY = y

        // pauses turntable rotation + sync the camera with the remote data
        this.viewer.dispatchEvent(new CustomEvent('camera-change', { detail: { source: 'user-interaction' } }))
    }

    recenter(pointer: { clientX: number; clientY: number }) {
        this.panning = false

        if (
            Math.abs(pointer.clientX - this.startX) > this.tapDistance ||
            Math.abs(pointer.clientY - this.startY) > this.tapDistance
        )
            return

        const hit = this.viewer.positionAndNormalFromPoint(pointer.clientX, pointer.clientY)

        this.viewer.cameraTarget = hit === null ? 'auto auto auto' : hit.position.toString()

        // sync the camera with the remote data
        this.viewer.dispatchEvent(new CustomEvent('camera-change', { detail: { source: 'user-interaction' } }))
    }

    startTour() {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                cancelIcon: { enabled: true },
                scrollTo: true,
                scrollToHandler: element => {
                    if (!element) return

                    const rect = element.getBoundingClientRect()

                    if (rect.y <= 0 || rect.y >= window.innerHeight) element.scrollIntoView({ behavior: 'smooth' })
                }
            }
        })
        const buttons: Record<string, Vue | undefined> = {
            color: this.$refs.colorButton,
            camera: this.$refs.cameraButton,
            environment: this.$refs.environmentButton,
            sample: this.$refs.sampleButton,
            texture: this.$refs.textureButton
        }
        const createButtonText = (text: string): string => {
            return `<span>${this.$t(text).toString()}</span>`
        }
        ;['complete', 'hide', 'cancel'].forEach(event =>
            tour.on(event, () => {
                this.disposeTour()

                if (event === 'cancel' || event === 'complete') {
                    localStorage.setItem(TOUR_STATE_STORAGE_ITEM_ID, TourState.COMPLETE)
                }

                if (!this.isSlideshow) this.$el.scrollIntoView()
            })
        )

        this.tour = tour
        this.$store.commit(MutationType.ADD_ACTIVE_TOUR_SLIDE_INDEX, this.index)

        tour.addStep({
            title: this.$t('sample_viewer_tour.intro_title').toString(),
            text: this.$t('sample_viewer_tour.intro_body').toString(),
            buttons: [
                {
                    text: createButtonText('tour.skip'),
                    action: () => {
                        localStorage.setItem(TOUR_STATE_STORAGE_ITEM_ID, TourState.COMPLETE)
                        tour.hide()
                    },
                    secondary: true
                },
                {
                    text: createButtonText('tour.start'),
                    action: tour.next,
                    classes: 'shepherd-button-next'
                }
            ]
        })

        TOUR_STEPS.forEach(step => {
            const button = buttons[step]

            if (button) {
                const element = button.$el as HTMLElement

                tour.addStep({
                    id: TOUR_STEP_ID_PREFIX + step,
                    modalOverlayOpeningRadius: calculateTourStepOverlayRadius(element),
                    title: this.$t(`sample_viewer_tour.${step}_title`).toString(),
                    text: this.$t(`sample_viewer_tour.${step}_body`).toString(),
                    attachTo: {
                        element,
                        on: 'auto'
                    },
                    canClickTarget: false,
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: () => {
                                        return [0, mediaIsMin('md') ? 55 : 20]
                                    }
                                }
                            },
                            {
                                name: 'preventOverflow',
                                options: {
                                    padding: 20
                                }
                            }
                        ]
                    },
                    buttons: [
                        {
                            text: createButtonText('tour.back'),
                            action: tour.back,
                            secondary: true
                        },
                        {
                            text: createButtonText('tour.next'),
                            action: tour.next,
                            classes: 'shepherd-button-next'
                        }
                    ]
                })
            }
        })

        tour.addStep({
            title: this.$t('sample_viewer_tour.outro_title').toString(),
            text: this.$t('sample_viewer_tour.outro_body').toString(),
            buttons: [
                {
                    text: createButtonText('tour.back'),
                    action: tour.back,
                    secondary: true
                },
                {
                    text: createButtonText('tour.end'),
                    action: tour.next,
                    classes: 'shepherd-button-next'
                }
            ]
        })

        tour.start()
    }

    disposeTour() {
        this.$store.commit(MutationType.REMOVE_ACTIVE_TOUR_SLIDE_INDEX, this.index)

        if (!this.tour) return

        if (this.tour.isActive()) this.tour.hide()

        this.tour = null
    }

    resizeTour() {
        if (!this.tour) return

        const currentStep = this.tour.getCurrentStep()
        const currentStepIndex = currentStep ? this.tour.steps.indexOf(currentStep) : -1

        TOUR_STEPS.forEach(step => {
            const tourStep = this.tour?.getById(TOUR_STEP_ID_PREFIX + step)

            if (tourStep) {
                const target = tourStep.getTarget()

                if (target) {
                    tourStep.updateStepOptions({
                        modalOverlayOpeningRadius: calculateTourStepOverlayRadius(target)
                    })
                }
            }
        })

        // the current step isn't updated, so we force the tour to restart
        if (currentStepIndex !== 0) this.tour.show(0)
    }

    afterResize() {
        this.resizeTour()
    }

    @Watch('nextSampleId')
    async onNextSampleIdChange() {
        if (!this.progressBar) return

        if (!this.viewerLoaded) {
            this.activeSampleId = this.nextSampleId
            return
        }

        this.autoRotate = false

        const blob: Blob = await this.viewer.toBlob()

        this.sampleUpdated = true
        this.previousSampleImage = URL.createObjectURL(blob)

        this.progressBar.reset()

        if (this.isLeader) {
            this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
                id: this.realSlug,
                sampleNextSampleId: this.nextSampleId
            } as RemoteSlide)
        }

        gsap.to(this.$refs.loading, {
            autoAlpha: 1,
            duration: 0.7
        }).then(() => {
            this.viewerIsLoading = true
            this.viewerLoaded = false
            this.activeSampleId = this.nextSampleId
        })
    }

    @Watch('viewerIsLoading')
    onViewerIsLoadingChange() {
        if (this.viewerIsLoading && this.progressBar) this.progressBar.appear()
    }

    @Watch('transitionIsAnimated')
    onTransitionIsAnimatedChange() {
        this.checkIfViewerShouldLoad()
    }

    onViewerLoaded() {
        this.viewerIsLoading = false
        this.viewerLoaded = true
        this.autoRotate = true

        const sceneExtras = this.viewer.originalGltfJson?.scenes?.[0]?.extras as ModelViewerSceneExtras
        this.cameras = sceneExtras?.['modelviewer.cameras'] || []

        // get leather materials by name
        this.leatherMaterials = this.viewer.model.materials.filter(
            material =>
                material.name.toLowerCase() === LEATHER_MATERIAL_NAME.toLowerCase() ||
                material.name.toLowerCase() === LEATHER_MATERIAL_LEGACY_NAME.toLowerCase()
        )
        this.leatherStitchMaterials = this.viewer.model.materials.filter(
            material =>
                material.name.toLowerCase() === LEATHER_STITCH_MATERIAL_NAME.toLowerCase() ||
                material.name.toLowerCase() === LEATHER_STITCH_MATERIAL_LEGACY_NAME.toLowerCase()
        )
        this.secondaryLeatherMaterials = this.viewer.model.materials.filter(
            material =>
                material.name.toLowerCase() === SECONDARY_LEATHER_MATERIAL_NAME.toLowerCase() ||
                material.name.toLowerCase() === SECONDARY_LEATHER_MATERIAL_LEGACY_NAME.toLowerCase()
        )
        // reset default leather texture
        this.leatherDefaultTexture = null
        this.leatherDefaultStitchTexture = null
        this.secondaryLeatherDefaultTexture = null

        this.initLeatherVariant()

        if (this.progressBar)
            this.progressBar
                .leave()
                .then(this.hideLoading)
                .then(() => {
                    this.checkIfTourShouldStart()
                })

        if (this.hasPresentingLeader && !this.isLeader) this.updateScene()
        else this.initCamera()

        this.initMaterialColor()
        this.updateModelMaterialColor()

        if (this.isLeader) {
            this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
                id: this.realSlug,
                sampleLeatherVariant: this.leatherVariant
            } as RemoteSlide)
        }
    }

    onViewerError(event: CustomEvent) {
        if (event.detail.type === 'loadfailure') {
            this.viewerError = new HttpError(this.$t('sample_viewer_load_failure').toString(), 404)
        } else if (event.detail.type === 'webglcontextlost') {
            this.viewerError = new Error(this.$t('sample_viewer_context_lost').toString())
        } else {
            this.viewerError = new Error()
        }
    }

    onViewerCameraChange(event: CustomEvent) {
        if (!this.viewerLoaded) return

        if (event.detail.source === 'user-interaction' || (this.hasPresentingLeader && !this.viewerControlsAllowed)) {
            this.cameraIndex = -1
        }

        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleCameraOrbit: this.viewer.getCameraOrbit().toString(),
            sampleMinCameraOrbit: this.viewer.minCameraOrbit,
            sampleMaxCameraOrbit: this.viewer.maxCameraOrbit,
            sampleCameraTarget: this.viewer.cameraTarget,
            sampleFieldOfView: this.fieldOfView
        } as RemoteSlide)
    }

    onClickColorToggle() {
        this.toggleColor()
    }

    onViewerMouseDown(event: MouseEvent) {
        if (!this.navigationIsAllowed && !this.controlsAllowed) return

        this.autoRotate = false

        this.startX = event.clientX
        this.startY = event.clientY
        this.panning = event.button === 1 || event.ctrlKey || event.metaKey || event.shiftKey

        this.$emit('cancel-drag', event)

        if (!this.panning) return

        this.lastX = this.startX
        this.lastY = this.startY

        this.startPan()
        this.resetTurntable()

        event.stopPropagation()
    }

    onViewerTouchStart(event: TouchEvent) {
        const { targetTouches, touches } = event

        this.startX = targetTouches[0].clientX
        this.startY = targetTouches[0].clientY

        this.panning = targetTouches.length === 2 && targetTouches.length === touches.length

        if (!this.panning) return

        this.lastX = 0.5 * (targetTouches[0].clientX + targetTouches[1].clientX)
        this.lastY = 0.5 * (targetTouches[0].clientY + targetTouches[1].clientY)

        this.startPan()
    }

    onViewerMouseMove(event: MouseEvent) {
        if (!this.panning) return

        this.movePan(event.clientX, event.clientY)

        event.stopPropagation()
    }

    onViewerTouchMove(event: TouchEvent) {
        if (!this.panning || event.targetTouches.length !== 2) return

        const { targetTouches } = event
        const x = 0.5 * (targetTouches[0].clientX + targetTouches[1].clientX)
        const y = 0.5 * (targetTouches[0].clientY + targetTouches[1].clientY)

        this.movePan(x, y)
    }

    onViewerMouseUp() {
        // this.recenter(event)
        this.panning = false
    }

    onViewerTouchEnd(event: TouchEvent) {
        if (event.targetTouches.length === 0) {
            this.panning = false
            // this.recenter(event.changedTouches[0])
            //
            // if (event.cancelable) event.preventDefault()
        }
    }

    // onViewerMouseUp() {
    //     this.autoRotate = true
    // }

    // @Watch('sample')
    // onSampleChange() {
    // if (this.sample?.environments.length) {
    //     const environment = this.sample?.environments[0]
    //
    //     if (environment?.darkEnvironment) this.color = Color.DARK
    //     else if (environment?.lightEnvironment) this.color = Color.LIGHT
    // }
    // }

    @Watch('exposure')
    onExposureChange() {
        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleExposure: this.exposure
        } as RemoteSlide)
    }

    @Watch('autoRotate')
    onAutoRotateChange() {
        if (!this.isLeader) {
            if (!this.autoRotate && !this.navigationIsAllowed) this.viewer?.resetTurntableRotation()

            return
        }

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleAutoRotate: this.autoRotate
        } as RemoteSlide)
    }

    @Watch('color')
    onColorChangeInternal() {
        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleColor: this.color
        } as RemoteSlide)
    }

    @Watch('remoteSlides', { immediate: true })
    onRemoteSlidesChange() {
        if (this.isLeader || !this.isCurrent) return

        this.updateScene()
    }

    @Watch('controlsAllowed')
    onControlsAllowedChange() {
        if (this.isLeader || !this.isCurrent) return

        this.updateScene()
    }

    @Watch('tourIsAllowed')
    onTourIsAllowedChange() {
        if (!this.tourIsAllowed && this.tour) {
            this.disposeTour()
        }
    }

    @Watch('materialColor')
    onMaterialColorChange() {
        this.updateModelMaterialColor()

        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleMaterialColor: this.materialColor
        } as RemoteSlide)
    }

    @Watch('cameraIndex')
    onCameraIndexChange() {
        this.initCamera()
    }

    @Watch('leatherVariant')
    onLeatherVariantChange() {
        this.updateLeatherTexture()

        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.realSlug,
            sampleLeatherVariant: this.leatherVariant
        } as RemoteSlide)
    }

    onStartTour() {
        if (this.tourIsAllowed && this.viewerLoaded) this.startTour()
    }
}
