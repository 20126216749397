









































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import IconCheck from '@/assets/img/icons/check.svg?sprite'
import BaseImage from '@/components/BaseImage.vue'

export interface ListOption {
    value: unknown
    label: string
    color?: string
    image?: RoadizDocument | RawImage
    selected?: boolean
}

@Component({
    components: { BaseImage, IconCheck }
})
export default class BaseList extends Vue {
    @Prop() options!: ListOption[]
    @Prop() value!: unknown
    @Prop() unselectable!: boolean

    currentValue =
        typeof this.value !== 'undefined' ? this.value : this.options.find(option => option.selected)?.value || null

    get thumbFormats(): string {
        return JSON.stringify({ fit: '48x48' })
    }

    @Watch('currentValue')
    onCurrentColorChange() {
        this.$emit('input', this.currentValue)
    }

    @Watch('value')
    onValueChange(value: unknown) {
        this.currentValue = value
    }

    onItemClick(value: unknown) {
        this.currentValue = value === this.currentValue && this.unselectable ? null : value
    }
}
