















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

export interface TabsItem {
    label: string
    value: string
}

@Component
export default class BaseTabs extends Vue {
    @Prop() items!: TabsItem[]
    @Prop() value!: string | null

    onItemClick(value: string) {
        this.$emit('input', value)
    }
}
