



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import IconCross from '@/assets/img/icons/cross.svg?sprite'
import BaseTabs, { TabsItem } from '@/components/BaseTabs.vue'
import BaseList, { ListOption } from '@/components/BaseList.vue'
import SimpleBar from 'simplebar'

export interface PickerOptionGroup {
    value: string
    label: string
    options: ListOption[]
}

@Component({
    components: { BaseList, BaseTabs, IconCross }
})
export default class BasePicker extends Vue {
    @Prop() options!: ListOption[] | PickerOptionGroup[]
    @Prop() value!: unknown | Record<string, unknown>
    @Prop(Boolean) unselectable!: boolean
    @Prop(Boolean) scrollable!: boolean
    @Prop(Boolean) isOpen!: boolean

    currentTabItemValue: string | null = (this.options as PickerOptionGroup[])[0]?.value || null
    transitionName = 'slide-rtl'
    scrollbar: SimpleBar | null = null

    get hasTabs(): boolean {
        return this.valueIsRecord && Object.keys(this.value as object).length > 1
    }

    get tabsItems(): TabsItem[] {
        return (this.options as PickerOptionGroup[]).map((option: TabsItem) => ({
            label: option.label,
            value: option.value
        }))
    }

    get valueIsRecord(): boolean {
        return this.value && typeof this.value === 'object'
    }

    get currentModel(): unknown {
        return this.valueIsRecord
            ? this.currentTabItemValue
                ? (this.value as Record<string, unknown>)[this.currentTabItemValue]
                : null
            : this.value
    }

    set currentModel(value: unknown) {
        // undefined value could be lead to odd behavior with Record and defined options
        // e.g. color picker inside the Slide sample viewer
        if (typeof value === 'undefined') return

        this.$emit(
            'input',
            this.valueIsRecord
                ? this.currentTabItemValue
                    ? Object.assign({}, this.value as Record<string, unknown>, { [this.currentTabItemValue]: value })
                    : null
                : value
        )
    }

    get currentOptions(): ListOption[] {
        return (this.options as PickerOptionGroup[])[0]?.options
            ? ((this.options as PickerOptionGroup[]).find(
                  (option: PickerOptionGroup) => option.value === this.currentTabItemValue
              ) as PickerOptionGroup)?.options || (this.options[0] as PickerOptionGroup).options
            : this.options
    }

    beforeDestroy() {
        if (this.scrollbar) this.scrollbar.unMount()
    }

    @Watch('currentTabItemValue')
    onCurrentTabItemValueChange(value: string, oldValue: string) {
        const index = this.tabsItems.findIndex(item => item.value === value)
        const oldIndex = this.tabsItems.findIndex(item => item.value === oldValue)

        this.transitionName = index > oldIndex ? 'slide-rtl' : 'slide-ltr'
    }

    @Watch('isOpen')
    onIsOpenChange() {
        if (this.isOpen) {
            if (this.scrollable && !this.scrollbar) this.scrollbar = new SimpleBar(this.$el as HTMLElement)
        } else {
            this.scrollbar?.unMount()
            this.scrollbar = null
        }
    }
}
